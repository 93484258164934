import React, {useEffect} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setList as cartSetList} from '../../../redux/Cart';
import {setList as beverageSetList,setUnits} from '../../../redux/Beverage';
import {setUserDetail} from '../../../redux/User';
import {useAuth} from "../auth";

const BaseDataForUser = () => {

    const dispatch = useDispatch();
    const {auth, logout, setCurrentUser} = useAuth()

    useEffect(() => {

        fetchData();

    }, [dispatch]);

    const fetchData = async () => {

        try {

            const response = axios.get(`${process.env.REACT_APP_API_URL}/getAllForAdmin`, {
                headers: {
                    'Authorization': `Bearer ${auth?.api_token}`
                }
            })
                .then(response => {

                    if (response.status !== 200) {
                        throw new Error('Network response was not ok');
                    }

                    const data = response.data;


                    data.cart.map((c: any) => {

                        c.pours = [];

                        data.pours.map((p: any) => {

                            if (p.cart_id == c.id) {
                                c.pours.push(p)
                            }
                        });

                    });

                    dispatch(cartSetList(data.cart ? data.cart : []));
                    dispatch(beverageSetList(data.beverage ? data.beverage : []));
                    dispatch(setUnits(data.units ? data.units : []));
                    dispatch(setUserDetail(data.user ? data.user : []));

                })
                .catch(error => {
                    console.error('Error:', error);
                });

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    return null; // or you can return an empty fragment if necessary
};

export default BaseDataForUser;
