import exp from "node:constants";


export const DDrink = {
    id: "",
    name: "",
    price: 0,
    unit_id: 0,
    description: "",
    logo: ""
}

export const DInitialFormData = {
    id: "",
    name: "",
    price: 0,
    unit_id: 0,
    existingLogoUrl: "",
    logo: "",
    description: "",
};


