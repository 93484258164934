export function formatNumber(input: any) {

    if (typeof input === 'number') {
        return input;
    }

    // Ak vstup nie je reťazec, nemôžeme s ním pracovať
    if (typeof input !== 'string') {
        return input;
    }

    let cleanedInput = input.replace(/\s/g, '');
    cleanedInput = cleanedInput.replace(/,/g, '.');

    const parsedNumber = parseFloat(cleanedInput);

    if (isNaN(parsedNumber)) {
        return input;
    }

    return parsedNumber;
}
