import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export interface BeverageState {
    list: any,
    units: any,
}

const initialState: BeverageState = {
    list: [],
    units: [],
};

export const Beverage = createSlice({
    name: 'beverage',
    initialState,
    reducers: {
        setList: (state, action: PayloadAction<any>) => {
            state.list = action.payload;
        },
        setUnits: (state, action: PayloadAction<any>) => {
            state.units = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setList,setUnits} = Beverage.actions

export default Beverage.reducer
