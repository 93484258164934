import axios from 'axios';
export const URL = `${process.env.REACT_APP_API_URL}/transaction`;

function makeJsonForRequest(postData: any){

    return {
        dateFrom: postData.name,
        dateTo: postData.name,
        cartId: postData.name,
    };

}
export function all(postData: any) {
    return axios.post(URL+ '/all', postData).then(response => {
        console.log(response.data);
        return response.data;
    }).catch(error => {
        console.error(error);
    });

}
export function update(postData: any) {
    return axios.put(URL + '/' + postData.id, makeJsonForRequest(postData)).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });
}
export function deleteB(postDataId: string) {
    return axios.delete(URL + '/' + postDataId, {})
        .then(response => {
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        });
}
