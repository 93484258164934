import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export interface IUserState {
    detail: any,
}

const initialState: IUserState = {
    detail: [],
};

export const User = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetail: (state, action: PayloadAction<any>) => {
            state.detail = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setUserDetail} = User.actions

export default User.reducer
