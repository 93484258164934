/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import {DashboardPage} from "./DashboardPage"

const DashboardWrapper: FC = () => {
    const intl = useIntl();
    const [startDate, setStartDate] = useState<Date | null>(new Date());

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: "MENU.DASHBOARD"})}
            </PageTitle>
            <DashboardPage/>
        </>
    );
};

export {DashboardWrapper};
