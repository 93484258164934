import axios from 'axios';
export const URL = `${process.env.REACT_APP_API_URL}/carts`;

function makeJsonForRequest(postData: any){

    return {
        name: postData.name,
        type: postData.type,
        description: postData.description || '',
        address: postData.address || '',
        log: postData.log ? parseFloat(postData.log) : undefined,
        lat: postData.lat ? parseFloat(postData.lat) : undefined,
        tax: postData.tax ? parseFloat(postData.tax) : undefined,
        bar_id: postData.bar_id || null
    };

}
export function add(postData: any) {
    return axios.post(URL, makeJsonForRequest(postData)).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });

}
export function update(postData: any) {
    return axios.put(URL + '/' + postData.id, makeJsonForRequest(postData)).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });
}
export function deleteB(postDataId: string) {
    return axios.delete(URL + '/' + postDataId, {})
    .then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });
}
