import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { Cart } from "./Cart";

const CartsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Carts</PageTitle>
      <div className="col-xl-12">
        <Cart className="card-xxl-stretch mb-5 mb-xl-8" />
      </div>
    </>
  );
};

export { CartsWrapper };
