import exp from "node:constants";


export const DSelectedData = {
    id: "",
    name: "",
    position: -1,
    state: "",
    price: 0,
    unit: "",
    beverage_id: -1,
}

export const DTableData = [];


