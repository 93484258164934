import React, {FC, useState, useEffect} from "react";
import {Modal, Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {RootState} from "../../components/Store";
import {AddModalProps} from "../../interfaces/Beverages";
import {DInitialFormData} from "../../defaults/Beverages";

const AddModal: FC<AddModalProps> = ({show, handleClose, handleAdd}) => {
    const [formData, setFormData] = useState(DInitialFormData);
    const [logo, setLogo] = useState<File | null>(null);
    const [logoPreview, setLogoPreview] = useState<string | null>(null);
    const data = useSelector((state: RootState) => state);
    //const [selectedFile, setSelectedFile] = useState<string | null>(null);

    useEffect(() => {
        if (logo) {
            const objectUrl = URL.createObjectURL(logo);

            if (logo) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === 'string') {
                        //setSelectedFile(reader.result);
                    }
                };
                reader.readAsDataURL(logo);
            }

            setLogoPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [logo]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: Number(value)});
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setLogo(e.target.files[0]);
        }
    };

    const handleSubmit = () => {

        handleAdd(formData,logo);
        handleClose();

        setFormData(DInitialFormData);
        setLogo(null);
        setLogoPreview(null);

    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Row</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formLogo" className="mt-3">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        {logoPreview && (
                            <div className="mt-3">
                                <img
                                    src={logoPreview}
                                    alt="Logo Preview"
                                    style={{width: "30%", height: "auto"}}
                                />
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formName" className="mt-3">
                        <Form.Label>Name of beverage</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPrice" className="mt-3">
                        <Form.Label>Default price ($)</Form.Label>
                        <Form.Control
                            type="text"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUnit" className="mt-3">
                        <Form.Label>Unit</Form.Label>
                        <Form.Select
                            name="unit_id"
                            aria-label="Default select example"
                            onChange={handleSelectChange}
                            value={formData.unit_id}>
                            <option value={0}></option>
                            {data.beverage.units.map((option: any, index: number) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group controlId="formDescription" className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            rows={3}
                            placeholder="Enter description"
                            onChange={handleChange}
                            value={formData.description}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Row
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddModal;
